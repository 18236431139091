import React from 'react';
import { Promotion } from 'services/redemptionHistory';

export interface PromotionItemProps extends Promotion {}

function PromotionItem({
  exchange_count,
  promotion,
  total_revenue,
}: PromotionItemProps) {
  return (
    <div className="flex gap-3 py-3">
      <img
        className="h-[4.166875rem] w-[6.25rem]"
        src={promotion.banner_url}
        alt={promotion.description}
      />
      <div className="flex flex-1 flex-col justify-center">
        <p className="text-sm text-black">{promotion.name}</p>
        <p className="text-xs text-black">
          Canjes <b>{exchange_count}</b>
        </p>
        <p className="text-xs text-black">
          Dinero acumulado: <b>${total_revenue ? total_revenue : '0.00'}</b>
        </p>
        <p className="text-xs text-black">
          Vigencia: <b>{promotion.expiration}</b>
        </p>
      </div>
    </div>
  );
}

export default React.memo(PromotionItem);
